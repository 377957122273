<template>
<form @submit.prevent="">
    <div class="form-row">
        <v-text-field  v-if="isMobile" class="mobile-app-input mobile-app-input-modal" label="نام داشبورد جدید" id="dashboard-name" v-model="newDashboard"></v-text-field>
        <div class="col-12" v-if="!isMobile">
            <input type="text" id="dashboard-name" placeholder="نام داشبورد جدید" class="widget-input input-no-bg input-lg input-set-border w-100" v-model="newDashboard">
        </div>
    </div>
    <div class="form-row text-center mt-4">
        <div class="col-12">
            <a href="" @click.prevent="addDashboard()" :class="'m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-primary add-dashboard ' + (addEditLoading ? 'loading-active inline-flex' : '')"><i class="uil uil-plus-circle"></i> افزودن داشبورد</a>
        </div>
    </div>
</form>
</template>

<script>
// این کامپوننت مودال افزودن داشبورد میباشد
export default {
    name: 'DashboardsModal',
    props: ['data'],
    components: {},
    data: function () {
        return {
            newDashboard: '',
            default_id: '',
            addEditLoading: false,
            isMobile:false,
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            this.newDashboard = '';
        }, { immediate: true });
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        // این متد درخواست افزودن داشبورد را ارسال درخواست به سرور را هندل میکند
        addDashboard() {
            let api_data = {
                title: this.newDashboard,
            };
            this.addEditLoading = true;

            this.$helpers.makeRequest('POST', '/dashboard/store', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    this.$swal.fire({
                        icon: "success",
                        title: "داشبورد با موفقیت ایجاد شد",
                        showConfirmButton: !1,
                        timer: 2000
                    });

                    let dashboard = api_response.data.response.dashboard;
                    this.$parent.emitData('addDashboard', { dashboard: this.$helpers.unbindObject(dashboard) }, true);
                } else {
                    this.$swal.fire({
                        icon: "error",
                        title: "مشکلی در ایجاد داشبورد رخ داده است",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                }
                this.addEditLoading = false;
            });
        },
    },
}
</script>
